import { useMemo, useEffect, useState } from "react"
import Table from "./Table"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/AuthInterceptor'
import * as XLSX from "xlsx";



const DownloadData = ({downloadTabledataHeaders,downloadTabledata}) =>{



const columns = useMemo(
    () => [
      {
        Header: 'User data',
        columns: [
          {
            Header: 'permalink',
            accessor: 'permalink',
          },
          {
            Header: 'video_id',
            accessor: 'video_id',
          },
          {
            Header: 'senderuserid',
            accessor: 'senderuserid',
          },
          {
            Header: 'senderscreenname',
            accessor: 'senderscreenname',
          },
          {
            Header: 'sender_followers_count',
            accessor: 'sender_followers_count',
          },
          {
            Header: 'title',
            accessor: 'title',
          },
          {
            Header: 'message',
            accessor: 'message',
          },
          {
            Header: 'sentiment_id',
            accessor: 'sentiment_id',
          },
          {
            Header: 'duration_id',
            accessor: 'duration_id',
          },{
            Header: 'createdtime',
            accessor: 'createdtime',
          },{
            Header: 'ist',
            accessor: 'ist',
          },{
            Header: 'network_name',
            accessor: 'network_name',
          },{
            Header: 'topic_name',
            accessor: 'topic_name',
          },{
            Header: 'phase_name',
            accessor: 'phase_name',
          },{
            Header: 'source_type',
            accessor: 'source_type',
          },{
            Header: 'theme_type',
            accessor: 'theme_type',
          }
        ],
      }
    ],
    []
  )




  const handleDownload = () => {
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = downloadTabledata.map((product, index) => ({
      permalink: product.permalink,
      video_id: product.video_id,
      senderuserid: product.senderuserid ,
      senderscreenname: product.senderscreenname,
      sender_followers_count: product.sender_followers_count,
      title: product.title,
      message: product.message,
      sentiment_id: product.sentiment_id ,
      duration_id: product.duration_id,
      createdtime: product.createdtime,
      ist: product.ist ,
      network_name: product.network_name ,
      topic_name: product.topic_name ,
      phase_name: product.phase_name,
      source_type: product.source_type, 
      theme_type: product.theme_type 
    }));

   

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      downloadTabledataHeaders,
    ]);

    XLSX.writeFile(workbook, "ReportFor2024.xlsx", { compression: true });
  };






    return(
        <>
        <div className="data__only_excelDownload">
          <div className="downloaddata__label">Download User Data</div>
        <div className="downloadExcell">
          {downloadTabledata && downloadTabledata ? <button className="btn btn-primary" onClick={handleDownload}>Download Excel</button> : <button className="btn btn-primary disabled">Wait...</button>}
          
          
          </div>
        </div>
         {/* <Table columns={columns} data={downloadTabledata} /> */}
        </>
    )

}


export default DownloadData