import { ArrowUp, Calendar } from 'react-feather';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState, Suspense } from 'react';
import {Chart as ChartJs, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend} from 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/AuthInterceptor'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import WordCloud from 'react-d3-cloud';
import Loadding from '../components/Loadding';

ChartJs.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend)

const ModelComparison = () =>{
 
  const convCheckboxRfe = useRef(null)
  const dayCheckboxRfe = useRef(null)


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const currentData = new Date().toJSON().slice(0,10)

    const defaultDeviceValue = {
     "device_name": "Q6B6",
      "id": 1
  };
    const defaultDurationStartValue = {
      "date_start": "L-30",
      "id": 16
  };
    const defaultDurationEndValue = {
      "date_end": "L+3",
      "id": 49
  };
  const defaultDeviceValue2 = {
   "device_name": "s23",
      "id": 3
};




const [loaddingns, setloaddingns] =useState(false)


    const [getOnLoadDeviceList, SetonLoadDeviceList] = useState([defaultDeviceValue])
    const [getDurationStartList, SetDurationStartList] =   useState([defaultDurationStartValue])
    const [getDurationEndList, SetDurationEndList] =   useState([defaultDurationEndValue])
    const [getOnLoadDeviceListNew, SetonLoadDeviceListNew] =   useState([defaultDeviceValue2])

  const [deviceValueChange, setdeviceValueChange] = useState(false)
  const [durationStartChange, setDurationStartChange] = useState(false)
  const [durationEndChange, setDurationEndChange] = useState(false)
  const [deviceSecChange, setdeviceSecChange] = useState(false)

  const [selectedDevice, SetselectedDevice]= useState({})
  const [selectedStartDate, SetselectedStartDate]= useState({})
  const [selectedEndDate, SetselectedEndDate]= useState({})
  const [selectedDeviceSec, SetselectedDeviceSec]= useState({})

    const [ConversationsTrend, setConversationsTrend] = useState([])
    const [conversationsDevice, setconversationsDevice] = useState([])
    const [conversationsFirstDevice, setconversationsFirstDevice] = useState({})
    const [conversationsSecDevice, setconversationsSecDevice] = useState({})
  
    const [sentimentDatanegative, setsentimentDatanegative] = useState({
      "share":"",
      "volume":""
    })
    const [sentimentDataneutral, setsentimentDataneutral] = useState({})
    const [sentimentDatapositive, setsentimentDatapositive] = useState({})
    

    const [overallNps, setoverallNps] = useState()
    const [conversationsChart, setConversationsChart] = useState()


    const [convonloadValue, setConvonloadValue]=useState()
    const [dayonloadValue, setDayonloadValue]=useState()


    const [ChartPlatformNps, setChartPlatformNps] =useState({})

    
    const [SentimentTrend, setSentimentTrend] = useState([])
    const [sentimentTrendFirstDevice, setsentimentTrendFirstDevice] = useState({})
    const [sentimentTrendSecDevice, setsentimentTrendSecDevice] = useState({})

    const [deviceMessage, setdeviceMessage] =useState('Please select different device')

    const [getDeviceName, setDeviceName]=useState('')
    const [getDeviceNameSec, setDeviceNameSec]=useState('')

    const [getFirstDeviceData, setFirstDeviceData]=useState({})
    const [getSecDeviceData, SetSecDeviceData]=useState({})



//tabbing
const [activeTab, setActiveTab] = useState('tab-11');

const handleClickTab = (event) => {
  setActiveTab(event.target.id);
};




    
// const onloaData = async() =>{
//     try {
//       await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}data_chart1`,{}
//     )
//       .then(function (response) {
//         if(response && response.status == '200'){
//           setConversationsTrend(response.data.data.datasets)
//         }else{
//           console.log(response)
//         }
//       })
//       .catch(function (error) {
//        console.log('error message')
//       });
//     } catch (error) {
//       console.log(error)
//       toast.error(error)
//     }
// } 


const onLoadDeviceList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}device_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
         // console.log(response.data.data)
          SetonLoadDeviceList(response.data.data)
          SetonLoadDeviceListNew(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}


const onLoadDurationStartList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
          //console.log(response.data.data)
          SetDurationStartList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const onLoadDurationEndList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
         // console.log(response.data.data)
          SetDurationEndList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}


var getAllloadDevice = []


// onloaData()
if(getAllloadDevice <= 2){
  getAllloadDevice.push(defaultDeviceValue.id)
  getAllloadDevice.push(defaultDeviceValue2.id)
}




const loadData ={
  "device_list": getAllloadDevice,
  "date_start": defaultDurationStartValue.id,
  "date_end": defaultDurationEndValue.id,
}






const getChartConversations = async (countData, frequencydata) =>{
  setloaddingns(true)
  console.log(countData,frequencydata)
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_conversations_multi_device`,{
      "api_data":{...countData, 'frequency':frequencydata}
    }
  )
    .then(function (response) {
      if(response && response.status == '200'){
        //console.log(response.data)
        setconversationsDevice(response.data.data)
        const a = Object.keys(response.data.data)[0]
        const b = Object.keys(response.data.data)[1]
        setconversationsFirstDevice(response.data.data[a])
        setconversationsSecDevice(response.data.data[b])

        setloaddingns(false)

       
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}



const getSentimentTrend = async (countData) =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_platform_nps_multi_device`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
       // console.log(response.data.data)
        const c = Object.keys(response.data.data)[0]
        const d = Object.keys(response.data.data)[1]
        setSentimentTrend(response.data.data)
        setsentimentTrendFirstDevice(response.data.data[c])
        setsentimentTrendSecDevice(response.data.data[d])
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const getchartDriversofSentimentmulti = async (countData) =>{
  console.log(countData)
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_drivers_of_sentiment_multi`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data)
       setDeviceName(Object.keys(response.data.data)[0])
       setFirstDeviceData(response.data.data[Object.keys(response.data.data)[0]])
       setDeviceNameSec(Object.keys(response.data.data)[1])
        SetSecDeviceData(response.data.data[Object.keys(response.data.data)[1]])
      
      }else{
       // console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

// const getchartDriversofSentimentmultiSec = async (countData) =>{
//   try {
//     await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_drivers_of_sentiment_multi`,{
//       "api_data":{...countData} ,      
//     }
//   )
//     .then(function (response) {
//       if(response && response.status === 200){
//         //console.log(response.data.data[getDeviceNameSec])
//         setDeviceNameSec(Object.keys(response.data.data)[0])
//         SetSecDeviceData(response.data.data[Object.keys(response.data.data)[0]])
//       }else{
//         console.log(response)
//       }
//     })
//     .catch(function (error) {
//      console.log('error message')
//     });
//   } catch (error) {
//     console.log(error)
//     toast.error(error)
//   }
// }

// deviceValueChange,selectedDevice,defaultDeviceValue.id,durationStartChange,selectedStartDate,defaultDurationStartValue.id,durationEndChange,selectedStartDate,selectedEndDate,defaultDurationEndValue.id,deviceSecChange,selectedDeviceSec,defaultDeviceValue2.id


useEffect(()=>{
  getAllloadDevice=[]
  onLoadDeviceList()
  onLoadDurationStartList()
  onLoadDurationEndList()
 
  const finalDeviceValueB = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueB = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueB = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalDeviceSecValueB = deviceSecChange  ? selectedDeviceSec : defaultDeviceValue2.id

   
    const formDataB = {
      "device_list": [parseInt(finalDeviceValueB), parseInt(finalDeviceSecValueB)],
      "date_start": parseInt(finalStartDateValueB),
      "date_end": parseInt(finalEndDateValueB),
  }     




  getChartConversations(formDataB, dayCheckboxRfe.current.value)
  getSentimentTrend(formDataB)
    setConvonloadValue(convCheckboxRfe.current.value)
    setDayonloadValue(dayCheckboxRfe.current.value)
 
    getchartDriversofSentimentmulti(formDataB)

  //getchartDriversofSentimentmultiSec(formDataB)


// getchartDriversofSentimentmulti({
//   "device_list":parseInt(finalDeviceValueB),
//   "date_start": parseInt(finalStartDateValueB),
//   "date_end": parseInt(finalEndDateValueB),
// })

// getchartDriversofSentimentmultiSec({
// "device_list":parseInt(finalDeviceSecValueB),
// "date_start": parseInt(finalStartDateValueB),
// "date_end": parseInt(finalEndDateValueB),
// })






}, [])



// const onChnageGetnewdeviceList = async (deviceId) =>{
//   try {
//     await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}device_list_exclude`,{
//       "api_data":{"device_id": JSON.parse(deviceId)} ,      
//     }
//   )
//     .then(function (response) {
//       if(response && response.status === 200){
//         console.log(response.data)
//         SetonLoadDeviceListNew(response.data.data)
//        // SetonLoadDeviceList(response.data.data)

//       }else{
//         console.log(response)
//       }
//     })
//     .catch(function (error) {
//      console.log('error message')
//     });
//   } catch (error) {
//     console.log(error)
//     toast.error(error)
//   }
// }

const handleDeviceChange = (event) =>{
 // alert(event.target.value);
  SetselectedDevice(event.target.value)
  setdeviceValueChange(true)
//onChnageGetnewdeviceList(event.target.value)
 var finalDeviceSecValueAs = deviceSecChange  ? selectedDeviceSec : defaultDeviceValue2.id
//   const finalDeviceValueAs = deviceValueChange ? selectedDevice : defaultDeviceValue.id
//  console.log(finalDeviceSecValueAs)
//  console.log(finalDeviceValueAs)

//  if(finalDeviceValueAs == event.target.value){
//   SetselectedDeviceSec({})
//  }

if(event.target.value == finalDeviceSecValueAs ){
  alert('please select different device for Select Device2')
}


}

const handleDeviceChangeSec =(event)=>{
  SetselectedDeviceSec(event.target.value)
  setdeviceSecChange(true)
 // onChnageGetnewdeviceList(event.target.value)

 if(event.target.value == selectedDevice){
  alert('please select different device for Select Device1')
}

}


const changestartDuration = async (duradionId) =>{
 // alert(duradionId)
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list_get_right_list`,{"api_data": {
      "duration_id": JSON.parse(duradionId)
    }
  }
    )
      .then(function (response) {
        if(response && response.status === 200){
            console.log(response.data.data)
            SetDurationEndList(response.data.data)
            console.log(getDurationEndList)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
       toast.error(error)
   
      });
   
  }




const handleDatestart = (event) =>{
  SetselectedStartDate(event.target.value)
  setDurationStartChange(true)
  changestartDuration(event.target.value)
}
 


const changeEndDuration = async (duradionId) =>{
  //alert(duradionId)
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list_get_left_list`,{"api_data": {
      "duration_id": JSON.parse(duradionId)
    }
  }
    )
      .then(function (response) {
        if(response && response.status === 200){
            console.log(response.data.data)
            SetDurationStartList(response.data.data)
            console.log(getDurationEndList)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
       toast.error(error)
   
      });
   
  }



const handleDateend = (event) =>{
  SetselectedEndDate(event.target.value)
  setDurationEndChange(true)
  changeEndDuration(event.target.value)
}



const handleChacboxChangeconv = (event) =>{
  setConvonloadValue(event.target.value)
 
  const finalDeviceValueACO = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueACO = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueACO = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalDeviceSecValueACO = deviceSecChange  ? selectedDeviceSec : defaultDeviceValue2.id

  const formDataACO = {
    "device_list": [parseInt(finalDeviceValueACO), parseInt(finalDeviceSecValueACO)],
    "date_start": parseInt(finalStartDateValueACO),
    "date_end": parseInt(finalEndDateValueACO),
    //"platform": parseInt(finalPlateformValue)
}

  if(event.target.value === 'NPS Daily'){
    ///alert(event.target.value);
    getChartConversations(formDataACO, "daily")
    getSentimentTrend(formDataACO,  "daily")
  }
  else{
    getChartConversations(formDataACO, dayonloadValue)
    getSentimentTrend(formDataACO, dayonloadValue)
  }

}

const handleChacboxChangeday = (event) =>{
  setDayonloadValue(event.target.value)

  const finalDeviceValueA = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueA = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueA = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalDeviceSecValueA = deviceSecChange  ? selectedDeviceSec : defaultDeviceValue2.id

   
    const formDataA = {
      "device_list": [parseInt(finalDeviceValueA), parseInt(finalDeviceSecValueA)],
      "date_start": parseInt(finalStartDateValueA),
      "date_end": parseInt(finalEndDateValueA),
      //"platform": parseInt(finalPlateformValue)
  }


  getChartConversations(formDataA, event.target.value)
//  getSentimentTrend(loadData,  event.target.value)
}


const handleFormData = async (e) => {
  e.preventDefault()
  try {

    const finalDeviceValue = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValue = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValue = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalDeviceSecValue = deviceSecChange  ? selectedDeviceSec : defaultDeviceValue2.id

      console.log(finalDeviceValue)  
      const formData = {
        "device_list": [parseInt(finalDeviceValue), parseInt(finalDeviceSecValue)],
        "date_start": parseInt(finalStartDateValue),
        "date_end": parseInt(finalEndDateValue),
        //"platform": parseInt(finalPlateformValue)
    }


   

 

      
      if(formData.device_list[0] === formData.device_list[1]){
       alert('please select different device')
       setloaddingns(false)
        // getChartConversations()
        // getSentimentTrend()
      }else{
        //alert()
        // getChartConversations(formData, dayonloadValue)
        // getSentimentTrend(formData)

        if(convonloadValue === 'NPS Daily'){
          getChartConversations(formData, "daily")
          getSentimentTrend(formData)
        }
        else{
          getChartConversations(formData, dayonloadValue)
          getSentimentTrend(formData)
        }

      }


console.log(formData)


      getchartDriversofSentimentmulti(formData)

    //   getchartDriversofSentimentmulti({
    //     "device":parseInt(finalDeviceValue),
    //     "date_start": parseInt(finalStartDateValue),
    //     "date_end": parseInt(finalEndDateValue),
    // })
  
  //   getchartDriversofSentimentmultiSec({
  //     "device":parseInt(finalDeviceSecValue),
  //     "date_start": parseInt(finalStartDateValue),
  //     "date_end": parseInt(finalEndDateValue),
  // })
    
      //getCountConversations(formData)
      //getoverallNPS(formData)

     // getChartPlatformNps(rest)

      //console.log(getOnLoadDeviceList)
  } catch (error) {
    console.log(error)
  }
};







    return(
        <>
        <ToastContainer
    	position="top-right"
    	/>







{/* <WordCloud data={data} /> */}

<section className="outerwrapper">
        <section className="home__page">
            <section className="home__section1">
            <div className="row">
             <div className="col-xl-12 col-md-12">
              <div className="card">
                  <div className="card-body ">
                   <form id="compairmodel" onSubmit={handleFormData}>
                        <div className="row justify-content-between">
                         
                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="selectdevice">Select Device 1</label>
                                  <div className="input-group">
                                  <select className="form-select" id="selectdevice" placeholder="Campaign name"  name='device'  onChange={handleDeviceChange} defaultValue={defaultDeviceValue} >
                                         {getOnLoadDeviceList && getOnLoadDeviceList.map((data,index)=>(
                                          <option key={data.id} value={data.id}>{data.device_name}</option>
                                         ))}
                                        </select>
                                    </div>

                            </div>

                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="selectdevice2">Select Device 2</label>
                                  <div className="input-group">
                                  <select className="form-select" id="selectdevice2" placeholder="Campaign name"  name='device2'  onChange={handleDeviceChangeSec} defaultValue={defaultDeviceValue2} >
                                         {getOnLoadDeviceListNew && getOnLoadDeviceListNew.map((data,index)=>(
                                          <option key={data.id} value={data.id}>{data.device_name}</option>
                                         ))}
                                        </select>
                                    </div>

                            </div>

                            <div className="form-group col-md-4">
                                <label className="form-label" htmlFor="pc-datepicker-10">Date/Time</label>
                                

                          
                                  <div className="input-group date mycustomdate">
                                        {/* <input type="range" class="form-range"  id="start__date" placeholder="Start date"  name='startdate' step="1"  onChange={handledatachange}/> */}
                                        <select className="form-select" id="startdate" name='date_start' onChange={handleDatestart} defaultValue={defaultDurationStartValue}>

                                         {getDurationStartList && getDurationStartList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}


                                        </select>
                                        <span className="input-group-text" max={currentData}>
                                        <Calendar color="#888" size="13"/>
                                        </span> 
                           
                                        <select className="form-select" id="enddate" name='date_end' onChange={handleDateend} defaultValue={defaultDurationEndValue}>
                                        {getDurationEndList && getDurationEndList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}

                                        </select>
                                        {/* <input type="range" class="form-range" id="end__date" placeholder="End date" name='enddate' step="1" onChange={handledatachange} /> */}
                                    </div>

                            </div>

                            <div className='form-group col-md-1 text-right' style={{paddingTop:'30px'}}>
                            <button type="submit" className="btn btn-dark">Search</button>
                            </div>

                            
                        </div>

                      
                      </form>
                  </div>
              </div>
             </div>
      </div>
            </section>

            <section className='sectiontabbing'>
            <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'tab-11' ? 'active' : ''}`}
            id="tab-11"
            onClick={handleClickTab}
          >
            Conversations
          </button>
          <button
            className={`tab-button ${activeTab === 'tab-21' ? 'active' : ''}`}
            id="tab-21"
            onClick={handleClickTab}
          >
            Sentiment Trend
          </button>
          <button
            className={`tab-button ${activeTab === 'tab-31' ? 'active' : ''}`}
            id="tab-31"
            onClick={handleClickTab}
          >
            Device compare
          </button>
        </div>
        <div className="tabs-content">
            {/* start tab1*/}
          <div className={`tab-page ${activeTab === 'tab-11' ? 'active' : ''}`}>
            
          <section className='home__section3 home__section33'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Conversations Trend</div>
                      <div className="card-body">
                      
<div className="row justify-content-between radiofilter">
  <div className="col-md-6 d-flex">
  <div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="conversations" ref={convCheckboxRfe} onChange={handleChacboxChangeconv} type="radio" value="Conversations" checked={convonloadValue === "Conversations"}  />
  Conversations
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Daily" checked={convonloadValue === "NPS Daily"} />
  NPS Daily
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Cumulative" checked={convonloadValue === "NPS Cumulative"}  />
  NPS Cumulative
  </label>
</div>
  </div>


  {convonloadValue && convonloadValue === 'NPS Daily' ? '' : 
  <div className="col-md-6 d-flex justify-content-end">
  <div className="form-check">
 
  <label className="form-check-label" >
  <input className="form-check-input" name="days" ref={dayCheckboxRfe} onChange={handleChacboxChangeday} type="radio" value="daily" checked={dayonloadValue === "daily"}  />
  Daily
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="weekly" checked={dayonloadValue === "weekly"} />
  Weekly
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="monthly" checked={dayonloadValue === "monthly"}  />
  Monthly
  </label>
</div>
  </div>
}
  
 
</div>


{loaddingns ? <Loadding/> :
   <Line height="60vw" data={{
                                  responsive: true,
                                    labels: conversationsFirstDevice && conversationsFirstDevice['labels'],
                                    datasets: [{
                                      label: Object.keys(conversationsDevice)[0],
                                     data:convonloadValue === 'NPS Daily' ? conversationsFirstDevice && conversationsFirstDevice['data_nps'] : convonloadValue === 'Conversations' ? conversationsFirstDevice && conversationsFirstDevice['data_convo'] : conversationsFirstDevice && conversationsFirstDevice['cumu_nps'],
                                      borderWidth: 2,
                                      borderColor:convonloadValue === 'NPS Daily' ? '#7086a7' : '#ffb1c1' ,
                                      backgroundColor:convonloadValue === 'NPS Daily' ? '#7086a7' : '#ffb1c1' ,
                                      datalabels: {
                                        display:false,
                                      },
                                    },
                                    {
                                      label:Object.keys(conversationsDevice)[1],
                                  
                                      data:convonloadValue === 'NPS Daily' ? conversationsSecDevice && conversationsSecDevice['data_nps'] : convonloadValue === 'Conversations' ? conversationsSecDevice && conversationsSecDevice['data_convo'] : conversationsSecDevice['cumu_nps'],

                                      borderWidth: 2,
                                      //borderDash: [10,5],
                                      borderColor:convonloadValue === 'NPS Daily' ? '#ffb1c1' : '#7086a7' ,
                                      backgroundColor:convonloadValue === 'NPS Daily' ? '#ffb1c1' : '#7086a7' ,
                                      datalabels: {
                                        display:false,
                                      }
                                    }],
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true
                                      }
                                    },
                                    plugins: {
                                      datalabels: {
                                          anchor: 'end', 
                                          align: 'end', 
                                          color: 'blue',
                                          font: {
                                              weight: 'bold',
                                          },
                                      }
                                  }
                                  }
                              }}/>              
                            
                            }
                       
                      </div>
                  </div>
                </div>
          </div>
</section>
            
          </div>
          {/* end tab1*/}


            {/* start tab2*/}
          <div className={`tab-page ${activeTab === 'tab-21' ? 'active' : ''}`}>


          <section className='section7'>
<div className="row">
    <div className="col-xl-12 col-md-12">
  
   <div className='row'>
    <div className="col-xl-6 col-md-6">
    <div className="card">
    <div className="card-header bgnone">{getDeviceName}</div>
    <div className="card-body">
    
    <Bar data={{
              responsive:true,
              type: 'bar',
              labels: getFirstDeviceData?.labels,
              datasets: [
                {
                  label: 'Negative Percentage',
                  data: getFirstDeviceData?.negative_percentage,
                  borderColor:"#ff8585",
                  backgroundColor: "#ff8585", 
                   datalabels: {
                    color: '#000',
                    align: 'center',
                    anchor: 'center',
                    rotation: -90,
                    formatter: function(value,) {
                      if(value > 5){
                        return `${value }%`
                      }else{
                        return ``
                      }
                    },
                    font: {
                      family:'SamsungOne'
                }
                  },
                  font: {
                    family:'SamsungOne'
                }
                },
                {
                  label: 'Positive Percentage',
                  data: getFirstDeviceData?.positive_percentage,
                  borderColor:"#bcd8a8",
                  backgroundColor:"#bcd8a8",  
                  datalabels: {
                    color: '#000',
                    align: 'center',
                    anchor: 'center',
                    rotation: -90,
                    formatter: function(value,) {
                      if(value > 5){
                        return `${value }%`
                      }else{
                        return ``
                      }
                    },
                    font: {
                      family:'SamsungOne'
                }
                  },
                  font: {
                    family:'SamsungOne'
                }
                }
              ],
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Chart.js Bar Chart'
                  },
                  font: {
                    family:'SamsungOne'
                }
                }
              },
            }}/>
        </div>
        </div>    
        </div>
        <div className="col-xl-6 col-md-6">
        <div className="card">
        <div className="card-header bgnone">{getDeviceNameSec}</div>
        <div className="card-body">
        <Bar data={{
                      responsive:true,
                      type: 'bar',
                      labels: getSecDeviceData?.labels,
                      datasets: [
                        {
                          label: 'Negative Percentage',
                          data: getSecDeviceData?.negative_percentage,
                          borderColor:"#ff8585",
                          backgroundColor: "#ff8585",
                          datalabels: {
                            color: '#000',
                            align: 'center',
                            anchor: 'center',
                            rotation: -90,
                            formatter: function(value,) {
                             if(value > 5){
                               return `${value }%`
                             }else{
                               return ``
                             }
                            },
                            font: {
                              family:'SamsungOne'
                        }
                          },
                          font: {
                            family:'SamsungOne'
                        }
                        },
                        {
                          label: 'Positive Percentage',
                          data: getSecDeviceData?.positive_percentage,
                          borderColor:"#bcd8a8",
                          backgroundColor:"#bcd8a8",
                          datalabels: {
                            color: '#000',
                            align: 'center',
                            anchor: 'center',
                            rotation: -90,
                            formatter: function(value,) {
                              if(value > 5){
                                return `${value }%`
                              }else{
                                return ``
                              }
                            },
                            font: {
                              family:'SamsungOne'
                        }
                          },
                          font: {
                            family:'SamsungOne'
                        }
                        }
                      ],
                      options: {
                        responsive: true,
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                          title: {
                            display: true,
                            text: 'Chart.js Bar Chart'
                          },
                          font: {
                            family:'SamsungOne'
                        }
                        }
                      },
                    }}/>
        </div>
        </div> 
        </div>

    </div>
    </div>
    </div>
</section>





          </div>
  {/* end tab2*/}

          <div className={`tab-page ${activeTab === 'tab-31' ? 'active' : ''}`}>

          <section className='home__section3'>
<div className="row">

                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Sentiment Trend
                      </div>
                      <div className="card-body">
                      {loaddingns ? <Loadding/> :     
                      <Bar height="80vw" data={{
                                  responsive: false,   
                                  type: 'bar',
                                    labels: sentimentTrendFirstDevice && sentimentTrendFirstDevice['labels'],
                                    datasets: [
                                      {
                                        label: Object.keys(SentimentTrend)[0],
                                        data: sentimentTrendFirstDevice && sentimentTrendFirstDevice['distribution'] ,
                                        backgroundColor:"#a4dcff",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          formatter: function(value,) {
                                            if(value > 1){
                                              return `${value }%`
                                            }
                                            else{
                                              return ` `
                                            }
                                          },
                                          font: {
                                            family:'SamsungOne'
                                      }
                                        },
                                        font: {
                                          family:'SamsungOne'
                                      }
                                      },
                                      {
                                        label: Object.keys(SentimentTrend)[1],
                                        data: sentimentTrendSecDevice && sentimentTrendSecDevice['distribution'],
                                        backgroundColor: "#ffc6a3",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          formatter: function(value,) {
                                            if(value > 1){
                                              return `${value }%`
                                            }
                                            else{
                                              return ` `
                                            }
                                          },
                                          font: {
                                            family:'SamsungOne'
                                      }
                                        },
                                        font: {
                                          family:'SamsungOne'
                                      }
                                      }
                                    ],
                                  
                                  options: {
                                    plugins: {
                                      title: {
                                        display: true,
                                        text: 'Chart.js Bar Chart - Stacked'
                                      },
                                      datalabels: {
                                        display: true,
                                        color: "black",
                                        font: {
                                          family:'SamsungOne'
                                      },
                                      },
                                      font: {
                                        family:'SamsungOne'
                                    }
                                    },
                                    responsive: true,
                                    interaction: {
                                      intersect: false,
                                    },
                                    scales: {
                                      x: {
                                        stacked: true,
                                        font: {
                                          family:'SamsungOne'
                                      }
                                      },
                                      y: {
                                        stacked: true,
                                        font: {
                                          family:'SamsungOne'
                                      }
                                      }
                                    }
                                  }
                              }}/>
                            }
                      </div>
                  </div>
                </div>
          </div>
</section>

          </div>
        </div>
            </section>

















        </section>



        <footer className="pc-footer">
    <div className="footer-wrapper container-fluid">
      <div className="row">
        <div className="col my-1">
          <p className="m-0">Cheil South West Asia</p>
        </div>
        
      </div>
    </div>
  </footer> 
  </section>

        </>
    )
}

export default ModelComparison